import LoadingAnimation from "../../components/LoadingAnimation";
import ConfirmationModal from "../demo payment/ConfirmationModal";
import {
    Box,
    Button,
    Container,
    Divider,
    FormControl, FormHelperText,
    Grid,
    InputLabel, MenuItem,
    Paper, Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import Img from "../../assets/images/channels4_profile.jpg";
import {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {useForm} from "react-hook-form";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {MobileDatePicker} from "@mui/x-date-pickers";
import moment from "moment";

const DemoSchedule = () => {
    const [loading, setLoading] = useState(false)
    const [price, setPrice] = useState(1200)
    const [user, setUser] = useState({
        username: null
    })
    const [conformation, setConformation] = useState(false)
    const [confirmData, setConformData] = useState({})
    const [transactionID, setTransactionID] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };
    let [date, setDate] = (useState<Date | null>(
        new Date()
    ))

    console.log("date",moment(date).format("yyyy-MM-DD"))
    let [unit, setUnit] = useState('');

    useEffect(() => {
        if (transactionID) {
            setConformation(true)
        }

    }, [transactionID])

    useEffect(() => {
        Auth.currentUserInfo().then(r => setUser(r))
    }, [])

    const {register, handleSubmit, setError, formState: {errors}} = useForm({
        defaultValues: {
            quantity: 1,
            number: "4005520000000129",
            month: "10",
            year: "22",
            securityCode: "002",
            numberOfPayments: "10",
            every: "1",
            unit: ""
        }
    });

    const onSubmit = async (data: any) => {
        setLoading(true)
        let time = moment(date).format("yyyy-MM-DD")
        let submitData = {
            body: {
                ...data,
                amount: price,
                currency: "USD",
                year: 22,
                merchantID: user.username,
                requestType: "PaymentMethodPaymentSchedulesRequest",
                maximumFailures: 1,
                invoiceNumber: "96126098",
                purchaseOrderNumber: "123055342",
                transactionOrigin: "ECOM",
                dynamicMerchantName: "MyWebsite",
                number: "4005520000000129",
                month: "10",
                cvv: "002",
                cardFunction: "CREDIT",
                language: "EN",
                country: "USA",
                startDate: time,
                unit: unit
            }
        }
        console.log(submitData)

        await API.post(
            "PaymentsGateway",
            `/PaymentSchedules/${user.username}`,
            submitData
        ).then(async (res: { ipgTransactionId: any; }) => {
            setLoading(false)
            await setConformData({
                ...res
            })
            await setTransactionID(res.ipgTransactionId)
            console.log('awsdata', confirmData)

            // submitData = {
            //     body: {
            //         transactionId: transactionID,
            //         merchantID:user.username
            //     }
            // }
            // await API.patch(
            //     "PaymentsGateway",
            //     `/Payments?${res.ipgTransactionId}`,
            // ).then(r=>console.log("patch::", r))
        }).catch(errors => {
            setLoading(false)
            showModal()
            console.log('1:::', errors);
            // console.log(error.response.data.error.details);
            // console.log('2:::', error.response.status);
            // setError(`custom`, {
            //     type: 'custom',
            //     message: errors
            // }, {shouldFocus: true})
        });
    }

    function handleChange(e: any) {
        console.log(e.target.value)
        setPrice(1200 * e.target.value)
    }


    const handleDate = (newValue: Date | null) => {
        setDate(newValue);
    };

    const handleUnit = (e: any) => {
        setUnit(e.target.value)
    };

    return (
        <Paper elevation={3} sx={{p: 2}}>
            <Container>
                <Typography variant={'h4'} textAlign={"center"}>
                    Payment
                </Typography>
                <Divider sx={{m: 2}}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <img src={Img} width={'100%'} alt={""}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ml: 2}}>
                            <Box>
                                <Typography>
                                    Name : Windows 11 Pro / AMD Ryzen™ 9 6900HX Mobile Processor (8-core/16-thread, 20MB
                                    cache, up to 4.9 GHz max boost)
                                </Typography>
                            </Box>
                            <Paper sx={{p: 2}}>
                                <Typography>
                                    Credit Card Info
                                </Typography>
                                <Grid container spacing={2} sx={{mt: .1}}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label={"Quantity"} {...register("quantity", {required: "Quantity is required"})}
                                            defaultValue={1} onChange={e => handleChange(e)}
                                            type={"number"} error={Boolean(errors.number)}/>
                                        <FormHelperText id="outlined-weight-helper-text"
                                                        sx={{color: "red"}}>{errors.quantity?.message}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{mt: 1}}>Total : {price} USD</Box>
                                    </Grid>
                                </Grid>
                                <Box sx={{mt: 2}}>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <TextField fullWidth {...register("number", {required: "Number is required"})}
                                                   label={"Card Number"} error={Boolean(errors.number)}/>
                                        <FormHelperText id="outlined-weight-helper-text"
                                                        sx={{color: "red"}}>{errors.number?.message}</FormHelperText>
                                        <Grid container sx={{mt: 1}} spacing={2}>
                                            <Grid item sm={6}>
                                                <TextField {...register("month", {required: "Month is required"})}
                                                           fullWidth
                                                           label={"Expire Month"} error={Boolean(errors.number)}/>
                                                <FormHelperText id="outlined-weight-helper-text"
                                                                sx={{color: "red"}}>{errors.month?.message}</FormHelperText>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <TextField
                                                    fullWidth {...register("year", {required: "Year is required"})}
                                                    label={"Expire Year"} error={Boolean(errors.number)}/>
                                                <FormHelperText id="outlined-weight-helper-text"
                                                                sx={{color: "red"}}>{errors.year?.message}</FormHelperText>
                                            </Grid>
                                        </Grid>
                                        <TextField
                                            fullWidth {...register("securityCode", {required: "Security code is required"})}
                                            sx={{mt: 2}} error={Boolean(errors.number)}
                                            label={"Security Code"}/>
                                        <FormHelperText id="outlined-weight-helper-text"
                                                        sx={{color: "red"}}>{errors.securityCode?.message}</FormHelperText>
                                        <Grid container sx={{mt: 1}} spacing={2}>
                                            <Grid item sm={6}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Stack spacing={3}>
                                                        <MobileDatePicker
                                                            label="Date mobile"
                                                            //inputFormat="MM/dd/yyyy"
                                                            value={date}
                                                            onChange={handleDate}
                                                            renderInput={(params) => <TextField {...params} />}

                                                        />

                                                    </Stack>
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <TextField
                                                    fullWidth {...register("numberOfPayments", {required: "Number of Payment is required"})}
                                                    label={"Number Of Payments"} error={Boolean(errors.number)}/>
                                                <FormHelperText id="outlined-weight-helper-text"
                                                                sx={{color: "red"}}>{errors.year?.message}</FormHelperText>
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{mt: 1}} spacing={2}>
                                            <Grid item sm={6}>
                                                <TextField
                                                    fullWidth {...register("every", {required: "Every is required"})}
                                                    type="number" label={"Every"} error={Boolean(errors.number)}/>
                                                <FormHelperText id="outlined-weight-helper-text"
                                                                sx={{color: "red"}}>{errors.every?.message}</FormHelperText>
                                                {errors.year && <span>This field is required</span>}
                                            </Grid>
                                            <Grid item sm={6}>
                                                {/*<FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        //value={age}
                                                        label="Age"
                                                        onChange={handleUnit}
                                                    >
                                                        <MenuItem value={'DAY'}>Day</MenuItem>
                                                        <MenuItem value={'YEAR'}>Year</MenuItem>
                                                        <MenuItem value={'MONTH'}>Month</MenuItem>
                                                    </Select>
                                                </FormControl>*/}
                                                <TextField
                                                    select
                                                    fullWidth
                                                    label="Select"
                                                    defaultValue=''
                                                    inputProps={register('unit', {
                                                        required: 'Unit is required',
                                                    })}
                                                    error={Boolean(errors.unit)}
                                                    onChange={handleUnit}
                                                >
                                                    <MenuItem value={'DAY'}>Day</MenuItem>
                                                    <MenuItem value={'YEAR'}>Year</MenuItem>
                                                    <MenuItem value={'MONTH'}>Month</MenuItem>
                                                </TextField>
                                                <FormHelperText id="outlined-weight-helper-text"
                                                                sx={{color: "red"}}>{errors.unit?.message}</FormHelperText>
                                                {errors.year && <span>This field is required</span>}
                                            </Grid>
                                        </Grid>
                                        <Button fullWidth type={'submit'} variant={'outlined'} sx={{mt: 2}}>
                                            Submit
                                        </Button>
                                        {loading && (
                                            <LoadingAnimation/>
                                        )}
                                    </form>
                                </Box>
                            </Paper>

                        </Box>


                    </Grid>
                </Grid>
            </Container>
        </Paper>
    )
}
export default DemoSchedule