import Routes from './routes';
import ThemeCustomization from './themes';
import ScrollTop from './components/ScrollTop';
import React, {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import {useNavigate} from "react-router-dom";

function App() {
    const router = useNavigate()

    const [state, setState]= useState(false)

    const [user, setUser] = useState({
        username: null
    })
    useEffect(()=>{
        Auth.currentUserInfo().then(r => {
            setUser(r)
            setState(true)
        })
    },[])


    useEffect(()=>{
        if (!user){
            router('/login')
        }
    },[state])
    return (
        <ThemeCustomization>
            <ScrollTop>
                {state && <Routes/>}
            </ScrollTop>
        </ThemeCustomization>
    );
}

export default App;
