import {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {searchRefunds} from "../../graphql/queries";
import moment from "moment/moment";
import LoadingAnimation from "../../components/LoadingAnimation";
import RefundRecord from "./RefundRecord";


const RefundAll = () => {

    const [allData, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const [user, setUser] = useState({
        username: undefined
    })

    let getData = async () => {
        const response: any = await API.graphql({
            query: searchRefunds,
            authMode: "AMAZON_COGNITO_USER_POOLS",
            variables: {filter: {requestID: {eq: user.username}}}
        })
        console.log('response', response.data.searchRefunds.items)
        return response.data.searchRefunds.items
    }

    useEffect(() => {
        Auth.currentUserInfo().then(r => setUser(r))
    }, [])


    useEffect(() => {
        if (user.username) {
            getData().then(r => {

                setLoader(false)
                setData(r.map((d : any)=> {
                    let refRes = JSON.parse(d?.refundResponse)
                    console.log('res',refRes)
                    console.log('allRecord::',r)
                    return {
                        paymentID : d.paymentID,
                        requestType : d.requestType,
                        refundTotal : `${d.refundTotal} (${d.refundCurrency})`,
                        approvedTotal :`${refRes.approvedAmount.total} (${refRes.approvedAmount.currency})`,
                        date : moment(d.tranTime).format('MMMM Do YYYY, h:mm:ss a').toString(),
                        merchantID: d.merchantID,
                        id:d.id,
                        transactionStatus: refRes.transactionStatus
                    }
                }))
            })
        }
    }, [user.username])

    console.log("allData",allData)

    return (
        <div>
            {allData.length===0 ?  ( loader ? <LoadingAnimation/> : <h3 >No data found</h3>) : (<RefundRecord data={allData}/>)}
        </div>
    )
}

export default RefundAll