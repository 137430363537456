import {axiosInstanceApiTx} from "../utils/Axios";
import { URLS } from "../utils/URL_Contant";
import { Config } from "../utils/config";

export class Api_Payment
{
    fetchCardToken = async () => await axiosInstanceApiTx.get(URLS.FETCH_PAYMENT_TOKEN);
    fetchPaymentInfo = async (merchantId: string) => await axiosInstanceApiTx.get(URLS.FETCH_PAYMETNS_INFO_BY_MERCHANT_ID(merchantId));
    makePayment = async (merchantId: string, reqParams: any) => 
    {
        console.log("::>Config",Config)
        await axiosInstanceApiTx.post(URLS.FETCH_PAYMETNS_INFO_BY_MERCHANT_ID(merchantId), reqParams);
    }
}