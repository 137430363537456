/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "oauth",
            "endpoint": "https://g7id21khb1.execute-api.ap-southeast-2.amazonaws.com/staging",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://5oirrzqgc5gjdkl6dmxvyw2pdy.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-s6jg6hjdrza2hemamxy7swxxfi",
    "aws_cognito_identity_pool_id": "ap-southeast-2:a503e53a-2dbf-420a-b072-64a0243cb194",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_cEAsDQw2l",
    "aws_user_pools_web_client_id": "ad23a28cb9f2g0u58da1ru8ua",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
