import {API, Auth} from "aws-amplify";
import {searchPayments} from "../../graphql/queries";
import {useEffect, useState} from "react";
import AllRecurring from "./AllRecurring";
import moment from "moment";
import LoadingAnimation from "../../components/LoadingAnimation";

const RecurringInfo = () => {

    const [allData, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const [user, setUser] = useState({
        username: undefined
    })


    let getData = async () => {
        try {
            const response: any = await API.graphql({
                query: searchPayments,
                authMode: "AMAZON_COGNITO_USER_POOLS",
                variables: {filter: { PaymentType: {eq: "Recurring"}, merchantID: {eq: user.username}}}
            })
            console.log(response.data.searchPayments.items)
            return response.data.searchPayments
        }catch (e){
            console.log(e)
        }

    }


    useEffect(()=> {
        Auth.currentUserInfo().then(r=>setUser(r))
    }, [])

    useEffect(() => {
        getData().then(res => {
            let total = res.total
            setLoader(false)
            setData(res.items.map((d : any)=> {
                console.log('allpayment::',res)
                let frequency = typeof d.frequency === 'string' ? JSON.parse(d.frequency) : d.frequency;
                console.log('frequency',d.merchant.fullName)
                return {
                    date : moment(d.tranTime).format('MMMM Do YYYY, h:mm:ss a').toString(),
                    startDate : d.startDate,
                    numberOfPayments: d.numberOfPayments,
                    taTotal : `${d.taTotal} (${d.taCurrency})`,
                    orderId: d.orderId,
                    state : d.state,
                    frequency : `Every ${frequency.every} ${frequency.unit.toLowerCase()}`,
                    id:d.id,
                    merchantID: d.merchantID,
                    merchantName: d.merchant.fullName,
                    requestType:d.requestType,
                    apiTraceId:d.apiTraceId,
                    total: total
                }
            }))
        })

    }, [user.username])

    console.log(allData)


    return (
        <>
            {allData.length===0 ? ( ( loader ? <LoadingAnimation/> : <h3 >No data found</h3>)) : (<AllRecurring data={allData}/>)}
        </>
    )
}

export default RecurringInfo