

const CompletePayment = () => {
  return (
      <>
          Payment Complete
      </>
  )
}

export default CompletePayment