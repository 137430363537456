
// require('dotenv').config();
const BASE_API_URL = "https://5zpfyxrcc7.execute-api.ap-southeast-2.amazonaws.com/staging";
const BASE_API_TX_URL = "https://j8eszhknlk.execute-api.ap-southeast-2.amazonaws.com/staging";


export const Config = {
    BASE_API_URL: process.env.REACT_APP_BASE_API_URL || BASE_API_URL,
    BASE_API_TX_URL: process.env.REACT_APP_BASE_API_TX_URL || BASE_API_TX_URL
}

export const API_GATEWAY_TOKEN = 'API_GATEWAY_TOKEN';