export const month =[
    {
    "short": "Jan",
    "label": 1,
    "days": 31
  },
  {
    "short": "Feb",
    "label": 2,
    "days": 28
  },
  {
    "short": "Mar",
    "label": 3,
    "days": 31
  },
   {
    "short": "Apr",
    "label": 4,
    "days": 30
  },
  {
    "short": "May",
    "label": 5,
    "days": 31
  },
 {
    "short": "Jun",
    "label": 6,
    "days": 30
  },
   {
    "short": "Jul",
    "label": 7,
    "days": 31
  },
   {
    "short": "Aug",
    "label": 8,
    "days": 31
  },
   {
    "short": "Sep",
    "label": 9,
    "days": 30
  },
   {
    "short": "Oct",
    "label": 10,
    "days": 31
  },
   {

    "short": "Nov",
    "label": 11,
    "days": 30
  },
   {
    "short": "Dec",
    "label": 12,
    "days": 31
  }
]

export const year = [
  {"year" : "2022", "label" : 22},
  {"year" : "2023", "label" : 23},
  {"year" : "2024", "label" : 24},
  {"year" : "2025", "label" : 25},
  {"year" : "2026", "label" : 26},
  {"year" : "2027", "label" : 27},
  {"year" : "2028", "label" : 28},
  {"year" : "2029", "label" : 29},
  {"year" : "2030", "label" : 30},
  {"year" : "2031", "label" : 31},
  {"year" : "2032", "label" : 32},
]
export const currencies =  [
    {
        value: 'AUD',
        label: 'AU$'
    },
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'EUR',
        label: '€',
    },
    {
        value: 'BTC',
        label: '฿',
    },
    {
        value: 'JPY',
        label: '¥',
    }

];
export const rowOption = [
    {
        label : 5
    },
    {
        label : 10
    },
    {
        label : 15
    },
]
