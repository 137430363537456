import { API_GATEWAY_TOKEN, Config } from '../config';
import Axios from 'axios';
import {fetchLocalVariable} from '../Common'

const axiosInstanceApi = Axios.create(
                            {
                                baseURL: Config.BASE_API_URL
                            }
                        );
    
axiosInstanceApi.interceptors.request.use(config => 
    {
        const token = fetchLocalVariable(API_GATEWAY_TOKEN);

    if (token) config.headers = {Authorization: `Bearer ${token}`};

    return config;
  }
);

const axiosInstanceApiTx = Axios.create(
                                {
                                    baseURL: Config.BASE_API_TX_URL
                                }
                            );

axiosInstanceApiTx.interceptors.request.use(config => 
    {
        const token = fetchLocalVariable(API_GATEWAY_TOKEN);

        if (token) config.headers = 
                        { 
                            Authorization: `Bearer ${token}`,
                        };

        return config;
    }
);

export {axiosInstanceApi,axiosInstanceApiTx};