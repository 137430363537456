import {API, Auth} from "aws-amplify";
import {searchPayments} from "../../graphql/queries";
import {useEffect, useState} from "react";
import PaymentInfo from "./PaymentInfo";
import moment from "moment";
import LoadingAnimation from "../../components/LoadingAnimation";
import { Api_AccountInfo } from "../../api/accountInfo";
import { Api_Payment } from "../../api/payment";


const PayInfo = () => {
    console.log("::> PayInfo")
    const [allData, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const [user, setUser] = useState({
        username: undefined
    })

    const [search, setSearch] = useState(null)

    const [limit, setLimit] = useState(5)
    const [from, setFrom] = useState(5)

    let updateFrom = (l : number, f : number) => {
        setLimit(l)
        setFrom(f)
    }

    let searchParam = (param: any) => {
        console.log('param',param)
        setSearch(param)
    }

    console.log('limit, page', limit,from)

    let getData = async () => await new Api_Payment().fetchPaymentInfo(user.username!);

    useEffect(()=> {
        Auth.currentUserInfo().then(r=>setUser(r))
    }, [])

    useEffect(() => {
        if (user.username) {
            getData().then(res => {
                let total = res.data.length
                setLoader(false);
                setData(res.data.map((d : any)=> {
                    return {
                        date : moment(d.tranTime).format('DD/MM/YYYY, h:mm a').toString(),
                        transactionType : d.transactionType,
                        transactionStatus : d.transactionStatus,
                        taTotal : `${d.taTotal} (${d.taCurrency})`,
                        approveTotal : d.approveTotal ? `${d.approveTotal} (${d.approveCurrency})` : 'Not approved',
                        transactionId: d.ipgTransactionId,
                        isRefund: d.isRefund,
                        id:d.id,
                        merchantID: d.merchantID,
                        merchantName: d.merchant.fullName,
                        transactionOrigin : d.transactionOrigin,
                        requestType: d.requestType,
                        orderId:d.orderId,
                        apiTraceId:d.apiTraceId,
                        customApiKey: d.merchant.customApiKey,
                        userName: d.merchant.userName,
                        total: total
                    }
                }))
            })

        }


    }, [user.username, limit])

    // console.log('allData',allData)

    return (
        <>
            {loader ? <LoadingAnimation/> : <PaymentInfo limit={limit} from={from} data={allData} updateFrom={updateFrom} searchParam={searchParam}/> }
        </>
    )
}

export default PayInfo