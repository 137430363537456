import {Auth} from "aws-amplify";
import {useEffect, useState} from "react";
import {Badge, Box, Card, Chip, Grid, Paper, Stack, Typography} from "@mui/material";


const MerchantInfo=()=>{

    const [profile, setProfile] = useState({
        sub: undefined,
        given_name: undefined,
        email: undefined,
        email_verified: undefined,
        phone_number_verified: undefined,
        family_name: undefined

    })

    useEffect(()=> {
        Auth.currentUserInfo().then(r => setProfile(r.attributes))
    },[])

    console.log("profile",profile)

    return(
        <div>
            <Box>
                <Paper sx={{p:5}}>
                    <Grid>
                        <Grid sm={24}>
                            <Stack direction="row" spacing={1}>
                                Merchant ID : <Chip label={profile.sub} color="success" size="small" variant="outlined"/>
                            </Stack>
                        </Grid>
                        <Grid sm={24}>
                            <Typography>
                                Merchant Name : {profile.given_name} {profile.family_name}
                            </Typography>
                        </Grid>
                        <Grid sm={24}>
                            <Typography>
                                Merchant Email : {profile.email}
                            </Typography>
                        </Grid>
                        <Grid sm={24}>
                            <Typography>
                                Merchant Email Verified? : {profile.email_verified ? (
                                <Chip label='Verified' color="success" size="small"  variant="outlined"/>) : (
                                <Chip label='Not Verified' color="success" size="small" variant="outlined"/>)}
                            </Typography>
                        </Grid>
                        <Grid sm={24}>
                            <Typography>
                                Merchant Phone Number Verified? : <Chip label='Not Verified' color="success" size="small" variant="outlined"/>
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    )
}
export default MerchantInfo;